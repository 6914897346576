import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/Seo/Seo';
import PostNavbar from '../components/PostNavbar/PostNavbar';
import SocialShare from '../components/SocialShare/SocialShare';
import PostLayout from '../layout/postLayout';
import Modular from './modular';

const Post = ({
  data: {
    wordpressPost: { acf, title },
  },
  location,
}) => (
  <PostLayout>
    <SEO title={title} />
    <PostNavbar />
    <Modular modules={acf.modules_post} />
    <SocialShare url={location.href} title={title} />
  </PostLayout>
);

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      title
      content
      acf {
        modules_post {
          ... on WordPressAcf_header {
            id
            title
            style
            meta
            rubrieken
            quote
            bg {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400) {
                    srcSetWebp
                    srcSet
                    src
                  }
                }
              }
            }
            bg_backdrop {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1400) {
                    srcSetWebp
                    srcSet
                    src
                  }
                }
              }
            }
          }

          ... on WordPressAcf_intro {
            text
            style
          }

          ... on WordPressAcf_paragraph {
            text
            background {
              source_url
            }
          }

          ... on WordPressAcf_slideshow {
            title
            afbeeldingen {
              id
              alt_text
              source_url
            }
          }

          ... on WordPressAcf_context_image {
            id
            context {
              label
              waarde
            }
            image {
              alt_text
              source_url
            }
          }

          ... on WordPressAcf_image {
            id
            grootte
            image {
              alt_text
              source_url
            }
          }

          ... on WordPressAcf_quote {
            id
            text
          }
        }
      }
    }
  }
`;

export default Post;
